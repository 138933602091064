// extracted by mini-css-extract-plugin
export var CardsSection__button = "index-module--CardsSection__button--14b70";
export var CardsSection__description = "index-module--CardsSection__description--ffae2";
export var CardsSection__imgContainer = "index-module--CardsSection__imgContainer--f4bc8";
export var CardsSection__leftEllipse = "index-module--CardsSection__leftEllipse--29f11";
export var CardsSection__rightEllipse = "index-module--CardsSection__rightEllipse--fed55";
export var CardsSection__textContainer = "index-module--CardsSection__textContainer--a0598";
export var CardsSection__textWrapper = "index-module--CardsSection__textWrapper--10722";
export var CardsSection__title = "index-module--CardsSection__title--caebc";
export var CardsSection__wrapper = "index-module--CardsSection__wrapper--bc3e5";
export var POSSection = "index-module--POSSection--605ce";
export var POSSection__button = "index-module--POSSection__button--ddd8d";
export var POSSection__card = "index-module--POSSection__card--3157d";
export var POSSection__description = "index-module--POSSection__description--9f908";
export var POSSection__image = "index-module--POSSection__image--2c146";
export var POSSection__title = "index-module--POSSection__title--dd369";
export var VASSection__button = "index-module--VASSection__button--772d1";
export var VASSection__container = "index-module--VASSection__container--94766";
export var VASSection__description = "index-module--VASSection__description--478f0";
export var VASSection__imgContainer = "index-module--VASSection__imgContainer--ce0ae";
export var VASSection__textContainer = "index-module--VASSection__textContainer--cac5e";
export var VASSection__title = "index-module--VASSection__title--ed7a4";
export var VASSection__wrapper = "index-module--VASSection__wrapper--7af7b";
export var add_margin = "index-module--add_margin--609b6";
export var awardsSection = "index-module--awardsSection--95cf7";
export var awardsSection__awards = "index-module--awardsSection__awards--43cc0";
export var awardsSection__title = "index-module--awardsSection__title--2bafa";
export var getInTouchSection = "index-module--getInTouchSection--5d637";
export var getInTouchSection__button = "index-module--getInTouchSection__button--9fff7";
export var getInTouchSection__description = "index-module--getInTouchSection__description--e7a15";
export var getInTouchSection__title = "index-module--getInTouchSection__title--583b6";
export var heroSection__backgroundImg = "index-module--heroSection__backgroundImg--6838d";
export var heroSection__backgroundImgWrapper = "index-module--heroSection__backgroundImgWrapper--b1708";
export var heroSection__button = "index-module--heroSection__button--d4da1";
export var heroSection__button__wrapper = "index-module--heroSection__button__wrapper--367a7";
export var heroSection__content = "index-module--heroSection__content--67dc4";
export var heroSection__description = "index-module--heroSection__description--ed9c6";
export var heroSection__hero_img__notification = "index-module--heroSection__hero_img__notification--7abed";
export var heroSection__hero_img_desktop = "index-module--heroSection__hero_img_desktop--91bb8";
export var heroSection__hint = "index-module--heroSection__hint--878f0";
export var heroSection__title = "index-module--heroSection__title--e38e9";
export var heroSection__wrapper = "index-module--heroSection__wrapper--f9b6b";
export var horizontal__Img = "index-module--horizontal__Img--62fd4";
export var partnersSection = "index-module--partnersSection--4ca7f";
export var partnersSection__description = "index-module--partnersSection__description--aeffb";
export var partnersSection__partners = "index-module--partnersSection__partners--31f50";
export var partnersSection__title = "index-module--partnersSection__title--033da";
export var productsSection = "index-module--productsSection--7551d";
export var productsSection__description = "index-module--productsSection__description--04e53";
export var productsSection__product = "index-module--productsSection__product--f57d5";
export var productsSection__product__brands = "index-module--productsSection__product__brands--b34e6";
export var productsSection__product__button = "index-module--productsSection__product__button--ce6bb";
export var productsSection__product__description = "index-module--productsSection__product__description--7f89c";
export var productsSection__product__image = "index-module--productsSection__product__image--e9f4f";
export var productsSection__product__subtitle = "index-module--productsSection__product__subtitle--5b802";
export var productsSection__product__title = "index-module--productsSection__product__title--8a246";
export var productsSection__products = "index-module--productsSection__products--3ce3c";
export var productsSection__title = "index-module--productsSection__title--201c0";
export var resourcesSection = "index-module--resourcesSection--476d2";
export var resourcesSection__card = "index-module--resourcesSection__card--1085c";
export var resourcesSection__card__button = "index-module--resourcesSection__card__button--872bf";
export var resourcesSection__card__content = "index-module--resourcesSection__card__content--2f58a";
export var resourcesSection__card__description = "index-module--resourcesSection__card__description--88441";
export var resourcesSection__card__title = "index-module--resourcesSection__card__title--483b1";
export var resourcesSection__cards = "index-module--resourcesSection__cards--5550f";
export var resourcesSection__image = "index-module--resourcesSection__image--56ba4";
export var resourcesSection__title = "index-module--resourcesSection__title--ba987";
export var vertical__Img = "index-module--vertical__Img--7139a";