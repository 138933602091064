import * as React from "react";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import { useLocation } from "@reach/router";
import { motion } from "framer-motion";

// Assets
import DARALogo from "../images/clients/dara-logo.svg";
import BRGRLogo from "../images/clients/brgr-logo.svg";
import CoffeBerryLogo from "../images/clients/coffe-berry-logo.png";
import ElsagheerLogo from "../images/clients/elsagheer-logo.png";
import NestleLogo from "../images/clients/nestle.svg";

// Styles
import * as styles from "../styles/components/Brands.module.css";

// Utils
import {
  revealContainerProps,
  revealContainerWithChildrenProps,
  revealVariant,
} from "../utils/animation-variants";

const MotionLink = motion(Link);

const Brands = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const clientsLogos = [
    {
      name: "BRGR",
      image: BRGRLogo,
    },
    {
      name: "Coffee berry",
      image: CoffeBerryLogo,
    },
    {
      name: "DARA's ice cream",
      image: DARALogo,
    },
    {
      name: "Al Sagheer",
      image: ElsagheerLogo,
    },
    {
      name: "Nestle",
      image: NestleLogo,
    },
  ];

  return (
    <section className={styles.sectionContainer}>
      <div>
        <motion.h2
          {...revealContainerProps}
          variants={revealVariant}
          className={styles.title}
        >
          {t("brands.title")}
        </motion.h2>
        <motion.p
          {...revealContainerProps}
          variants={revealVariant}
          className={styles.subtitle}
        >
          {t("brands.subTitle")}
        </motion.p>
        <motion.div
          {...revealContainerWithChildrenProps}
          className={styles.logos}
        >
          {clientsLogos.map((logo) => (
            <motion.img
              key={logo.name}
              variants={revealVariant}
              src={logo.image}
              alt={logo.name}
            />
          ))}
        </motion.div>
        <MotionLink
          variants={revealVariant}
          to="/contact-us"
          state={{ referrer: location.href }}
          className={`button with-icon button--gradient ${styles.button}`}
        >
          {t("brands.button")}
        </MotionLink>
      </div>
    </section>
  );
};

export default Brands;
