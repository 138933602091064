import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import { GatsbyImage } from "gatsby-plugin-image";

import * as styles from "../styles/components/SliderTabs.module.css";

// Utils
import {
  revealContainerProps,
  revealVariant,
  revealContainerWithChildrenProps,
} from "../utils/animation-variants";

const MotionLink = motion(Link);

const SliderTabs = ({ tabItems }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const [activeSlide, setActiveSlide] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  const intervalRef = useRef(null);

  useEffect(() => {
    clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      if (!isHovered) {
        setActiveTab((prevTab) => (prevTab + 1) % tabItems.length);
        setActiveSlide((prevTab) => (prevTab + 1) % tabItems.length);
      }
    }, 3000);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [tabItems.length, isHovered]);

  const handleTabClick = (index) => {
    setActiveTab(index);
    setActiveSlide(index);
  };

  const handleHover = (hoverState) => {
    setIsHovered(hoverState);
  };

  return (
    <section className={`${styles.heroSection__wrapper}`}>
      <motion.h2
        {...revealContainerProps}
        variants={revealVariant}
        className={styles.productsSection__title}
      >
        {t("home.productsSection.title")}
      </motion.h2>
      <motion.p
        {...revealContainerProps}
        variants={revealVariant}
        className={styles.productsSection__description}
      >
        {t("home.productsSection.description")}
      </motion.p>
      <div>
        <div
          className={`${styles.tabTitlesContainer}`}
          onMouseEnter={() => handleHover(true)}
          onMouseLeave={() => handleHover(false)}
        >
          {tabItems.map((tab, index) => (
            <>
              <div
                key={index}
                className={`cursor-pointer ${
                  activeTab === index && !tab.disabled
                    ? styles.tabTitle__activeState
                    : styles.tabTitle__wrapper
                }`}
                onClick={() => handleTabClick(index)}
              >
                {tab.tabTitle ? tab.tabTitle : tab.title}
              </div>
              {index !== tabItems.length - 1 &&
                !(activeTab === index || activeTab === index + 1) && <span />}
            </>
          ))}
        </div>
        {!tabItems[activeTab].disabled && (
          <div
            className={`${styles.tabContent__wrapper}`}
            onMouseEnter={() => handleHover(true)}
            onMouseLeave={() => handleHover(false)}
          >
            <motion.div
              {...revealContainerWithChildrenProps}
              variants={revealVariant}
              className={`${
                tabItems[activeTab].aspectRatio < 1
                  ? `grid grid-cols-[3fr_3fr]`
                  : `grid grid-cols-[3.7fr_1.8fr]`
              } ${styles.tabContent__product}`}
              key={tabItems[activeTab].title}
            >
              <motion.div
                variants={revealVariant}
                className={`${styles.tabContent__image} ${
                  tabItems[activeTab].aspectRatio > 1
                    ? "elevetion-animation max-w-[334px] lg:w-full"
                    : ""
                }`}
              >
                <GatsbyImage
                  image={tabItems[activeTab].image}
                  alt={tabItems[activeTab].title}
                />
              </motion.div>
              <motion.div className={`${styles.tabContent__text}`}>
                <motion.h2
                  {...revealContainerProps}
                  variants={revealVariant}
                  className={styles.tabContent__title}
                >
                  {tabItems[activeTab].title}
                </motion.h2>
                <motion.p
                  variants={revealVariant}
                  className={styles.tabContent__subtitle}
                >
                  {tabItems[activeTab].subtitle}
                </motion.p>
                <motion.p
                  variants={revealVariant}
                  className={styles.tabContent__description}
                >
                  {tabItems[activeTab].description}
                </motion.p>

                {tabItems[activeTab].brands.length > 0 ? (
                  <motion.div
                    variants={revealVariant}
                    className={styles.tabContent__brands}
                  >
                    {tabItems[activeTab]?.brands.map((brand) => (
                      <motion.a
                        variants={revealVariant}
                        href={brand.link}
                        target="_blank"
                      >
                        <motion.img src={brand.img} />
                      </motion.a>
                    ))}
                  </motion.div>
                ) : (
                  <span className={styles.displayNone} />
                )}
                <div>
                  {tabItems[activeTab].link ? (
                    <MotionLink
                      variants={revealVariant}
                      to={tabItems[activeTab].link}
                      className={`button button--gradient ${styles.tabContent__button}`}
                    >
                      {tabItems[activeTab].button}
                    </MotionLink>
                  ) : (
                    tabItems[activeTab].url && (
                      <motion.a
                        key={tabItems[activeTab].url}
                        href={tabItems[activeTab].url}
                        className={`button button--gradient ${styles.tabContent__button}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {tabItems[activeTab].button}
                      </motion.a>
                    )
                  )}
                </div>
              </motion.div>
            </motion.div>
          </div>
        )}
      </div>
      {/* Slide dots */}
      {!tabItems[activeTab].disabled && (
        <ul className={styles.slideDots}>
          {tabItems.map((_, index) => (
            <li
              key={index}
              className={`${styles.slideDot} ${
                activeSlide === index ? styles.activeSlideDot : ""
              }`}
              onClick={() => handleTabClick(index)}
            />
          ))}
        </ul>
      )}
    </section>
  );
};

export default SliderTabs;
