// extracted by mini-css-extract-plugin
export var activeSlideDot = "SliderTabs-module--activeSlideDot--6bd92";
export var activeYellowSlideDot = "SliderTabs-module--activeYellowSlideDot--2c51e";
export var add_margin = "SliderTabs-module--add_margin--ca0c1";
export var displayNone = "SliderTabs-module--displayNone--273ed";
export var heroSection__backgroundImg = "SliderTabs-module--heroSection__backgroundImg--c850d";
export var heroSection__backgroundImgWrapper = "SliderTabs-module--heroSection__backgroundImgWrapper--ee7b0";
export var heroSection__hero_img__notification = "SliderTabs-module--heroSection__hero_img__notification--198bd";
export var heroSection__hero_img_desktop = "SliderTabs-module--heroSection__hero_img_desktop--57d88";
export var heroSection__wrapper = "SliderTabs-module--heroSection__wrapper--0c825";
export var horizontal__Img = "SliderTabs-module--horizontal__Img--fc1aa";
export var productsSection__description = "SliderTabs-module--productsSection__description--d88a4";
export var productsSection__title = "SliderTabs-module--productsSection__title--265e7";
export var slideDot = "SliderTabs-module--slideDot--3002f";
export var slideDots = "SliderTabs-module--slideDots--256de";
export var slideYellowDot = "SliderTabs-module--slideYellowDot--e7686";
export var tabContent__brands = "SliderTabs-module--tabContent__brands--931b7";
export var tabContent__button = "SliderTabs-module--tabContent__button--d0144";
export var tabContent__description = "SliderTabs-module--tabContent__description--d4487";
export var tabContent__image = "SliderTabs-module--tabContent__image--c7d5a";
export var tabContent__product = "SliderTabs-module--tabContent__product--208c5";
export var tabContent__subtitle = "SliderTabs-module--tabContent__subtitle--948f2";
export var tabContent__text = "SliderTabs-module--tabContent__text--fa737";
export var tabContent__title = "SliderTabs-module--tabContent__title--18984";
export var tabContent__wrapper = "SliderTabs-module--tabContent__wrapper--37b2e";
export var tabTitle__activeState = "SliderTabs-module--tabTitle__activeState--79145";
export var tabTitle__wrapper = "SliderTabs-module--tabTitle__wrapper--de231";
export var tabTitlesContainer = "SliderTabs-module--tabTitlesContainer--8b80d";
export var vertical__Img = "SliderTabs-module--vertical__Img--1ae99";
export var yellowSlideDots = "SliderTabs-module--yellowSlideDots--e07dd";