import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

import * as styles from "../styles/components/SliderTabs.module.css";

// Utils
import { revealVariant } from "../utils/animation-variants";

const Slider = ({ slides }) => {
  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setActiveSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 3500);

    return () => {
      clearInterval(timer);
    };
  }, [slides.length]);

  const handleSlideClick = (index) => {
    setActiveSlide(index);
  };

  return (
    <div className="flex flex-col items-center xl:flex-row">
      <motion.div
        variants={revealVariant}
        className={styles.heroSection__hero_img_desktop}
      >
        <div
          className={`${styles.heroSection__backgroundImgWrapper} ${
            slides[activeSlide].id === 2
              ? "lg:!top-[35px] !top-[100px]"
              : "xl:!top-[152px] !top-[100px]"
          } `}
        >
          {slides[activeSlide].images.map((img, index) => (
            <motion.img
              alt="Zeal Pay"
              src={img.image}
              key={img.id}
              className={`${styles.heroSection__backgroundImg} ${
                slides[activeSlide].id === 2 && "!w-full"
              }`}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              // transition={{ ease: "circInOut" }}
            />
          ))}

          {slides[activeSlide].notification ? (
            <div className={styles.heroSection__hero_img__notification}>
              <motion.img
                className="elevetion-animation lg:ml-[15px]"
                src={slides[activeSlide].notification}
                alt="Your rewards is available 😍🚀"
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
              />
            </div>
          ) : (
            <span className="hidden"></span>
          )}
        </div>
      </motion.div>
      <ul className={styles.yellowSlideDots}>
        {slides.map((_, index) => (
          <li
            key={index}
            className={`${styles.slideYellowDot} ${
              activeSlide === index ? styles.activeYellowSlideDot : ""
            }`}
            onClick={() => handleSlideClick(index)}
          />
        ))}
      </ul>
    </div>
  );
};

export default Slider;
