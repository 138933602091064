import * as React from "react";
import { graphql } from "gatsby";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";

// Components
import Seo from "../components/SEO";
import Layout from "../components/Layout";
import MouseScrollAnimated from "../components/MouseScrollAnimated";
import YellowSection from "../components/YellowSection";
import Brands from "../components/Brands";
import ScheduleSection from "../components/ScheduleSection";
import SliderTabs from "../components/SliderTabs";
import Slider from "../components/Slider";
import { Newsletter } from "../components/Footer";
import useScreenSize from "../hooks/useScreenSizes";
import { useLocation } from "@reach/router";

// Assets
import BarclaysAward from "../images/awards/barclays.png";
import SetSquared from "../images/awards/setsquared.png";
import Paytech from "../images/awards/paytech.png";
import Enterprise from "../images/awards/enterprise.png";
import VisaAward from "../images/awards/visa.png";
import Worldbank from "../images/awards/worldbank.png";
import heroNotification from "../images/Notification.png";
import NetworkLogo from "../images/partners/network-logo.svg";

import mobile from "../images/homepage-hero-desktop1.png";
import pos from "../images/homepage-hero-desktop2.png";
import laptop from "../images/laptop-mockup.svg";

import OracleLogo from "../images/partners/oracle.png";
import IngenicoLogo from "../images/partners/ingenico.png";
import sagheer from "../images/brands/sagheer.png";
import nestle from "../images/brands/nestle.png";
import brgr from "../images/brands/brgr.png";
import daras from "../images/brands/daras.png";

// Styles
import * as styles from "../styles/pages/index.module.css";

// Utils
import {
  revealContainerProps,
  revealContainerWithChildrenProps,
  revealVariant,
} from "../utils/animation-variants";
import { revealCards, revealParent } from "../components/zeal-bin-loyalty/Hero";
const MotionLink = motion(Link);

const awards = [
  {
    id: "barclays",
    title: "Barclays Award",
    image: BarclaysAward,
  },
  {
    id: "enterprise",
    title: "Enterprise",
    image: Enterprise,
  },
  {
    id: "paytech",
    title: "Paytech",
    image: Paytech,
  },
  {
    id: "visa",
    title: "Visa Award",
    image: VisaAward,
  },
  {
    id: "setsquared",
    title: "SetSquared Award",
    image: SetSquared,
  },
  {
    id: "worldbank",
    title: "World Bank Award",
    image: Worldbank,
  },
];

const HeroSection = () => {
  const { t } = useTranslation();
  const slides = [
    {
      id: 1,
      images: [
        { id: "11", image: mobile },
        { id: "12", image: pos },
      ],
      notification: heroNotification,
    },
    {
      id: 2,
      images: [{ id: "21", image: laptop }],
      notification: null,
    },
  ];
  return (
    <section className={styles.heroSection__wrapper}>
      <motion.div
        {...revealContainerWithChildrenProps}
        className={`${styles.heroSection__content} sectionContainer text-center lg:text-left`}
      >
        <motion.div
          variants={revealVariant}
          className="lg:max-w-[400px] xl:max-w-[600px]"
        >
          <h1
            className={styles.heroSection__title}
            dangerouslySetInnerHTML={{
              __html: t("home.heroSection.title", {
                highlight: `<span>${t("billions")}</span>`,
                highlight2: `<span>${t("billions")}</span>`,
              }),
            }}
          />
          <p className={styles.heroSection__description}>
            {t("home.heroSection.description")}
          </p>
          <p className={styles.heroSection__hint}>
            {t("home.heroSection.hint")}
          </p>
          <div className={styles.heroSection__button__wrapper}>
            <Link
              to="/products/zeal-pos"
              className={`${styles.heroSection__button} button button--primary`}
            >
              {t("home.heroSection.button")}
            </Link>
          </div>
        </motion.div>
        <Slider slides={slides} />
      </motion.div>
      <MouseScrollAnimated />
    </section>
  );
};

const AwardsSection = () => {
  const { t } = useTranslation();
  return (
    <section className={styles.awardsSection}>
      <div className="sectionContainer">
        <motion.h2
          {...revealContainerProps}
          variants={revealVariant}
          className={styles.awardsSection__title}
        >
          {t("home.awardsSection.title")}
        </motion.h2>
        <motion.div
          {...revealContainerWithChildrenProps}
          className={styles.awardsSection__awards}
        >
          {awards.map((award) => (
            <motion.img
              variants={revealVariant}
              key={award.id}
              src={award.image}
              alt="Award"
              data-award={award.id}
            />
          ))}
        </motion.div>
      </div>
    </section>
  );
};

const VASsSection = () => {
  const { t } = useTranslation();

  return (
    <section
      className={`sectionContainer ${styles.VASSection__container} mr-0 xl:m-auto`}
    >
      <motion.section className={`${styles.VASSection__wrapper} `}>
        <div className={`${styles.VASSection__textContainer}`}>
          <motion.p className={styles.VASSection__title}>
            {t("home.POSsSection.title")}
          </motion.p>
          <motion.p className={styles.VASSection__description}>
            {t("home.POSsSection.description")}
          </motion.p>
          <MotionLink
            variants={revealVariant}
            to="/sandbox/signup"
            className={`button with-icon button--gradient ${styles.VASSection__button}`}
          >
            {t("home.POSsSection.button")}
          </MotionLink>
        </div>
        <div className={styles.VASSection__imgContainer}>
          <StaticImage src={"../images/home-page-POS.png"} alt="pos" />
        </div>
      </motion.section>
    </section>
  );
};

const ProductsTabsSliderSection = ({ images }) => {
  const { t } = useTranslation();
  const { isMD } = useScreenSize();

  const tabItems = [
    {
      title: t("home.productsSection.product1.title"),
      subtitle: t("home.productsSection.product1.subtitle"),
      description: t("home.productsSection.product1.description"),
      button: t("home.productsSection.product1.button"),
      image: getImage(images[0].node.childImageSharp),
      aspectRatio:
        images[0].node.childImageSharp.gatsbyImageData.height /
        images[0].node.childImageSharp.gatsbyImageData.width,
      link: "/products/zeal",
      brands: [],
    },
    {
      title: t("home.productsSection.product2.title"),
      subtitle: t("home.productsSection.product2.subtitle"),
      description: t("home.productsSection.product2.description"),
      button: t("home.productsSection.product2.button"),
      image: getImage(images[1].node.childImageSharp),
      aspectRatio:
        images[1].node.childImageSharp.gatsbyImageData.height /
        images[1].node.childImageSharp.gatsbyImageData.width,
      link: "/products/zeal-dashboard",
      brands: [],
    },
    {
      title: t("home.productsSection.product3.title"),
      subtitle: t("home.productsSection.product3.subtitle"),
      description: t("home.productsSection.product3.description"),
      button: t("home.productsSection.product3.button"),
      image: getImage(images[2].node.childImageSharp),
      aspectRatio:
        images[2].node.childImageSharp?.gatsbyImageData.height /
        images[2].node.childImageSharp?.gatsbyImageData.width,
      link: "/products/zeal-bin-loyalty",
      brands: [],
    },
    {
      title: t("home.productsSection.product4.title"),
      tabTitle: t("home.productsSection.product4.tab.title"),
      subtitle: t("home.productsSection.product4.subtitle"),
      description: t("home.productsSection.product4.description"),
      button: t("home.productsSection.product4.button"),
      image: getImage(images[3].node.childImageSharp),
      aspectRatio:
        images[3].node.childImageSharp?.gatsbyImageData.height /
        images[3].node.childImageSharp?.gatsbyImageData.width,
      link: "/products/zeal-apps",
      brands: [
        { img: daras, link: `https://bit.ly/Dara-App` },
        { img: brgr, link: "https://bit.ly/BRGR-APP" },
        { img: nestle, link: "https://bit.ly/Nestle-App" },
        { img: sagheer, link: "https://bit.ly/Alsagheer-App" },
      ],
    },
    {
      title: t("home.productsSection.product5.title"),
      subtitle: t("home.productsSection.product5.subtitle"),
      description: t("home.productsSection.product5.description"),
      button: t("home.productsSection.product5.button"),
      image: isMD
        ? getImage(images[4]?.node.childImageSharp)
        : getImage(images[5]?.node.childImageSharp),
      aspectRatio:
        images[4]?.node.childImageSharp?.gatsbyImageData.height /
        images[4]?.node.childImageSharp?.gatsbyImageData.width,
      link: "/use-cases/fmcgs",
      brands: [],
    },
    // {
    //   title: t("home.productsSection.product6.title"),
    //   disabled: true,
    //   subtitle: t("home.productsSection.product2.subtitle"),
    //   description: t("home.productsSection.product2.description"),
    //   button: t("home.productsSection.product2.button"),
    //   image: getImage(images[2].node.childImageSharp),
    //   noImagePadding: true,
    //   aspectRatio:
    //     images[3].node.childImageSharp.gatsbyImageData.height /
    //     images[3].node.childImageSharp.gatsbyImageData.width,
    //   link: "/products/zeal-glc",
    //   brands: [],
    // },
  ];
  return <SliderTabs tabItems={tabItems} />;
};
const GetInTouchSection = () => {
  const { t } = useTranslation();
  const location = useLocation();
  return (
    <section className={styles.getInTouchSection}>
      <motion.div
        {...revealContainerWithChildrenProps}
        className={`${styles.getInTouchSection__content} sectionContainer`}
      >
        <motion.h2
          variants={revealVariant}
          className={styles.getInTouchSection__title}
        >
          {t("home.getInTouchSection.title")}
        </motion.h2>
        <motion.p
          variants={revealVariant}
          className={styles.getInTouchSection__description}
        >
          {t("home.getInTouchSection.description")}
        </motion.p>
        <MotionLink
          variants={revealVariant}
          to="/contact-us"
          state={{ referrer: location.href }}
          className={`button with-icon button--gradient ${styles.getInTouchSection__button}`}
        >
          {t("home.getInTouchSection.button")}
        </MotionLink>
      </motion.div>
    </section>
  );
};

const POSSection = ({ image }) => {
  const { t } = useTranslation();
  return (
    <section className={styles.POSSection}>
      <motion.div
        {...revealContainerWithChildrenProps}
        className={`${styles.POSSection__card} sectionContainer`}
      >
        <motion.div
          {...revealVariant}
          variants={revealVariant}
          className={styles.POSSection__image}
        >
          <GatsbyImage image={getImage(image)} alt=" & POS terminal" />
        </motion.div>
        <motion.div {...revealContainerWithChildrenProps}>
          <motion.h2
            variants={revealVariant}
            className={styles.POSSection__title}
          >
            {t("home.POSSection.title")}
          </motion.h2>
          <motion.p
            variants={revealVariant}
            className={styles.POSSection__description}
          >
            {t("home.POSSection.description")}
          </motion.p>
          <MotionLink
            to="/products/zeal-pos"
            variants={revealVariant}
            className={`button with-icon button--primary ${styles.POSSection__button}`}
          >
            {t("home.POSSection.button")}
          </MotionLink>
        </motion.div>
      </motion.div>
    </section>
  );
};

const CardHolderSection = () => {
  const { t } = useTranslation();

  return (
    <section className="bg-white">
      <motion.section
        className={`${styles.CardsSection__wrapper} sectionContainer`}
      >
        <motion.div
          {...revealContainerWithChildrenProps}
          className={`sectionContainer ${styles.CardsSection__textContainer}`}
        >
          <StaticImage
            src={"../images/ellipse.svg"}
            alt=""
            className={styles.CardsSection__leftEllipse}
          />
          <motion.div
            className={`${styles.CardsSection__textWrapper} max-w-[608px]`}
          >
            <motion.p className={styles.CardsSection__title}>
              {t("home.CardsSection.title")}
            </motion.p>
            <motion.p className={styles.CardsSection__description}>
              {t("home.CardsSection.description")}
            </motion.p>
            <MotionLink
              variants={revealVariant}
              to="/products/zeal-bin-loyalty"
              className={`button button--gradient ${styles.CardsSection__button}`}
            >
              {t("home.CardsSection.button")}
            </MotionLink>
          </motion.div>
        </motion.div>{" "}
        <div className={`${styles.CardsSection__imgContainer}`}>
          <motion.div
            className="h-[493px] hidden xl:block elevetion-animation"
            initial="hidden"
            animate="visible"
            variants={revealParent}
          >
            <motion.div variants={revealCards}>
              <StaticImage
                className="relative !z-50 top-[205px]"
                layout="fullWidth"
                formats={["auto", "webp", "avif"]}
                src="../../images/bin-loyalty/visa-3.png"
                alt="hero logo visa cards"
                width={456}
                placeholder="blurred"
                style={{
                  maxWidth: "456px",
                  width: "456px",
                }}
              />
            </motion.div>
            <motion.div variants={revealCards}>
              <StaticImage
                className="relative !z-40 !top-[-200px]"
                layout="fullWidth"
                formats={["auto", "webp", "avif"]}
                src="../../images/bin-loyalty/visa-2.png"
                alt="hero logo visa cards"
                width={456}
                placeholder="blurred"
                style={{
                  maxWidth: "456px",
                  width: "456px",
                }}
              />
            </motion.div>
            <motion.div variants={revealCards}>
              <StaticImage
                className="relative !z-30 !top-[-580px]"
                layout="fullWidth"
                formats={["auto", "webp", "avif"]}
                src="../../images/bin-loyalty/visa-1.png"
                alt="hero logo visa cards"
                width={456}
                placeholder="blurred"
                style={{
                  maxWidth: "456px",
                  width: "456px",
                }}
              />
            </motion.div>
          </motion.div>
          {/* mobile */}
          <motion.div
            variants={revealVariant}
            className="flex justify-center xl:hidden elevetion-animation"
          >
            <StaticImage
              className="relative z-50"
              layout="fullWidth"
              formats={["auto", "webp", "avif"]}
              src="../../images/bin-loyalty/hero-visa-mobile.png"
              alt="hero logo visa cards"
              width={280}
              height={362}
              style={{
                maxWidth: "280px",
                width: "280px",
                height: "362px",
              }}
            />
          </motion.div>
        </div>
        <StaticImage
          src={"../images/ellipse.svg"}
          alt=""
          className={styles.CardsSection__rightEllipse}
        />
      </motion.section>
    </section>
  );
};
// const ResourcesSection = ({ images }) => {
//   const { t } = useTranslation();
//   const ResourcesSectionData = [
//     {
//       title: t("home.resourcesSection.card2.title"),
//       description: t("home.resourcesSection.card2.description"),
//       button: t("home.resourcesSection.card2.button"),
//       image: BlogThumbnail,
//       href: 'https://blog.myzeal.app/'
//     },
//     {
//       title: t("home.resourcesSection.card1.title"),
//       description: t("home.resourcesSection.card1.description"),
//       button: t("home.resourcesSection.card1.button"),
//       image: PressThumbnail,
//       to: '/press-and-media',
//     },
//   ];

//   return (
//     <section className={`${styles.resourcesSection} sectionContainer`}>
//       <motion.h2 {...revealContainerProps} variants={revealVariant} className={styles.resourcesSection__title}>{t("home.resourcesSection.title")}</motion.h2>
//       <motion.div {...revealContainerWithChildrenProps} className={styles.resourcesSection__cards}>
//         {ResourcesSectionData.map((card) => (
//           <motion.div {...revealContainerWithChildrenProps} variants={revealVariant} className={styles.resourcesSection__card}>
//             <motion.div variants={revealVariant} className={styles.resourcesSection__image}>
//               <StaticImage src={card.image} alt={card.title} />
//             </motion.div>
//             <motion.div {...revealContainerWithChildrenProps} className={styles.resourcesSection__card__content}>
//               <motion.h3 variants={revealVariant} className={styles.resourcesSection__card__title}>
//                 {card.title}
//               </motion.h3>
//               <motion.p variants={revealVariant} className={styles.resourcesSection__card__description}>
//                 {card.description}
//               </motion.p>
//               {card.to ? (
//                 <MotionLink variants={revealVariant} to={card.to} className={`button button--secondary ${styles.resourcesSection__card__button}`}>
//                   {card.button}
//                 </MotionLink>
//               ) : (
//                 <motion.a variants={revealVariant} href={card.href} target="_blank" rel="noreferrer" className={`button button--secondary ${styles.resourcesSection__card__button}`}>
//                   {card.button}
//                 </motion.a>
//               )}
//             </motion.div>
//           </motion.div>
//         ))}
//       </motion.div>
//     </section>
//   );
// };

const PartnersSectionData = [
  {
    partner: "Network",
    logo: NetworkLogo,
  },
  {
    partner: "Oracle",
    logo: OracleLogo,
  },
  {
    partner: "Ingenico",
    logo: IngenicoLogo,
  },
];
const PartnersSection = () => {
  const { t } = useTranslation();
  return (
    <section className={`${styles.partnersSection} sectionContainer`}>
      <motion.h2
        {...revealContainerProps}
        variants={revealVariant}
        className={styles.partnersSection__title}
      >
        {t("home.partnersSection.title")}
      </motion.h2>
      <motion.p
        {...revealContainerProps}
        variants={revealVariant}
        className={styles.partnersSection__description}
      >
        {t("home.partnersSection.description")}
      </motion.p>
      <motion.div
        {...revealContainerWithChildrenProps}
        variants={revealVariant}
        className={styles.partnersSection__partners}
      >
        {PartnersSectionData.map((partner) => (
          <motion.img
            variants={revealVariant}
            key={partner.partner}
            src={partner.logo}
            alt={partner.partner}
          />
        ))}
      </motion.div>
      <motion.div className="max-w-[762px] m-auto">
        <Newsletter partnerSection={true} />
      </motion.div>
    </section>
  );
};

const HomePage = ({ data: { productsSectionImages, POSSectionImage } }) => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <Layout>
      <HeroSection />
      <AwardsSection />
      <VASsSection />
      <ProductsTabsSliderSection images={productsSectionImages.edges} />
      <PartnersSection />
      <GetInTouchSection />
      <POSSection image={POSSectionImage} />
      <CardHolderSection />
      <ScheduleSection
        title="home.calendly.title"
        subtitle="home.calendly.subtitle"
      />
      <Brands />
      <YellowSection
        title={t("home.yellowSection.title")}
        description={t("home.yellowSection.description")}
        to="/contact-us"
        state={{ referrer: location.href }}
        btnText={t("home.yellowSection.button")}
      />
      {/* <ResourcesSection /> */}
    </Layout>
  );
};

export default HomePage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    productsSectionImages: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        name: { regex: "/homepage-products/" }
      }
      sort: { order: ASC, fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
    POSSectionImage: file(name: { regex: "/homepage-join-movement/" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`;
export const Head = ({ pageContext }) => {
  const isArabic = pageContext.i18n.language === "ar";
  return <Seo isArabic={isArabic} title={isArabic ? "الرئيسية" : "Home"} />;
};
